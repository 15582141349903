import React, { useState } from 'react';


import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
// import Text from "common/src/components/Text";
// import Heading from "common/src/components/Heading";
import Container from 'common/src/components/UI/Container';
import ParticlesWebComponent from '../../ParticleWeb';
import BannerWrapper from '../bannerhome.style';
import { Link } from 'gatsby';
import { background, color, left } from 'styled-system';
import Background from 'common/src/assets/image/hosting/ikons/icon1.png';
import Background1 from 'common/src/assets/image/hosting/ikons/ikon2.png';
import Background2 from 'common/src/assets/image/hosting/ikons/ikon3.png';
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'
// import Homepageimage from "common/src/assets/image/hosting/sliderv2.svg";

// import RAPBotImage from "common/src/assets/image/hosting/Pages/Misc/Checklist.jpg";
// import Achievementimage from "common/src/assets/image/hosting/news/home_banner_analytics.jpg";
// import Achievementimage2 from "common/src/assets/image/hosting/news/home_banner_cioreview.jpg";
// import TypingEffectSection from "../../TypingEffect";
// import { color, height } from "styled-system";

// import './bannerother.css';

const BooknowBanner = ({
  row,
  mainrow,
  red,
  col,
  title,
  description,
  subdescription,
  button,
  textArea,
  advrow,
  advcolimg,
  advcoltext,
  adtext,
  adtitle,
  imagecont,
  column,
  pipe,
}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contact_number, setcontact_number] = useState('');
  const [message, setMessage] = useState('');
  const [shopStat, setshopChange] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const [isError, setIsError] = useState(false);

console.log(setcontact_number)
  // let regex = new RegExp('[a-z0-9]+@stackabuse.com');
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const validateEmail = (event) => {
    const email = event.target.value;
    setEmail(email);
    if (emailRegex.test(email)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const validatephone = (number) => {
    const contact_number = number;
    console.log(number);
    setcontact_number(contact_number);
    console.log(contact_number);

  
    if (contact_number.length<5) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  };
 
  const handleSubmit = async (e) => {
    if (!email) {
      return 'Email is required';
    } else if (!new RegExp(/\S+@\S+\.\S+/).test(email)) {
      return 'Incorrect email format';
    }

    e.preventDefault();
    try {
      let bodyRequest = {
        name: name,
        email: email,
        contact_number: contact_number,
        // website: 'book-a-demo',
      };
      let res = await fetch('https://forms.rapidautomation.ai/api/saveform', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(bodyRequest),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        setName('');
        setEmail('');
        setcontact_number('');
        setMessage('User created successfully');
      } else {
        setMessage('Some error occured');
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
        <div className="mt33">
        <Container>
          <div className="home_box">
            <div class="flex-container1 rcen mty3 mty7 wrap1">
              <div className="">
                <p className="red">Book a Demo</p>
                <p className="bookhead ">
                {shopStat === true
                    ? 'Start Your Hyperautomation Journey Here'
                    : "Congratulations you've taken the first step towards Hyper Automation"}
                </p>
                <p className="phead  ">
                Book a slot for a one-to-one product demo with one of our AI solution experts.

                </p>
                <p className="phead  ">
              
30 mins is all it takes to boost your productivity by 3X.
                </p>
                <div class="flexcons  g6">
                  <p>
                    <img src={Background} />

                    <p className="smw1">Identify use cases</p>
                  </p>

                  <p>
                    <img src={Background1} className="smw" />

                    <p className="smw1">Get a roadmap for success</p>
                  </p>

                  <p className="">
                    <img src={Background2} />

                    <p className="smw1">Project ROI & target quick wins</p>
                  </p>
                </div>

              </div>

              <div className=" mt7 mb5 mt5l">
                <div className="divcard7 bgw">
                  <p className="cardp">Book a Demo now</p>
                  <form onSubmit={handleSubmit}>
                    <input
                      className="inputstyle"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Name*"
                      required
                    />

                    <input
                      className="border-white"
                      type="email*"
                      value={email}
                      onChange={validateEmail}
                      placeholder="Work Email*"
                      required
                    />
  <p className='span1'>{isValid ?' ': 'Please Enter valid Email'}</p>
                    

  <PhoneInput
   
  country={'us'}
  value={contact_number}
  onChange={(number)=>validatephone(number)}
/>
{!isError ?' ': <p className='span3'>please Enter Valid Mobile Number</p>}
     
                  
                    
                    <p className="fs12 mt1r">
                      By proceeding, I agree to Rapid Accelaration Partners,
                      Inc’s Terms of Use and acknowledgethat I have read the
                      Privacy Policy.
                    </p>
                    <p className="fs12">
                      I also agree that Rapid Acceleration Partners, Inc or its
                      representatives may contact me by email, phone, at the
                      email address or number I provide for marketing purposes.
                    </p>
                    {name && email && contact_number ? (
                      <button
                        className="btnstyle"
                        onClick={() => setshopChange(!shopStat)}
                      >
                        Book a Demo
                      </button>
                    ) : (
                      <button
                        className="btnstyle"
                        onClick={() => setshopChange(!shopStat)}
                        disabled
                      >
                        Book a Demo
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

BooknowBanner.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  subdescription: PropTypes.object,
  button: PropTypes.object,
  red: PropTypes.object,
};

BooknowBanner.defaultProps = {
  red: {
    fontfamily: 'Open Sans',
    fontsize: '50px',

    color: 'red',
  },
  mainrow: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    width: [1, '100%', '100%', '100%', '100%'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    width: ['100%', '100%', '50%', '50%', '50%'],
    position: 'relative',
  },
  column: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: ['100%', '100%', '100%', '100%', '100%'],
    pt: ['10px', '10px', '75px', '75px', '75px'],
    position: 'relative',
  },
  textArea: {
    width: [1, '100%', '100%', '100%', '100%'],
  },
  title: {
    as: 'h1',
    fontSize: ['26px', '26px', '26px', '26px', '26px'],
    fontWeight: '700',
    // color: '#0f2137',
    // color: "#fff",
    color: '#000000',
    letterSpacing: '-0.025em',
    mt: ['20px', '20px', '25px', '25px', '25px'],
    // mb: ['20px', '20px', '25px', '25px', '25px'],
    mb: 0,
    lineHeight: '1.31',
    textAlign: 'center',
  },
  description: {
    as: 'h2',
    fontSize: ['26px', '26px', '26px', '26px', '26px'],
    // color: '#343d48cc',
    // color: "#fff",
    color: '#000000',
    lineHeight: '1.2',
    // mb: ['8px', '8px', '10px', '10px', '10px'],
    mb: '2px',
    textAlign: 'center',
  },
  subdescription: {
    fontSize: ['14px', '14px', '14px', '16px', '16px'],
    // color: '#343d48cc',
    color: '#fff',
    lineHeight: '1.75',
    mb: ['20px', '20px', '25px', '25px', '25px'],
    textAlign: 'center',
  },
  button: {
    title: 'Search',
    type: 'button',
    fontSize: '18px',
    fontWeight: '500',
    color: '#fff',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    iconPosition: 'left',
  },
  cler: {
    color: 'red',
  },
  advrow: {
    mt: '15px',
    mb: '20px',
    width: '70%',
    flexBox: true,
    flexWrap: 'wrap',
    minHeight: '100px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  advcolimg: {
    width: ['100%', '100%', '100%', '50%', '50%'],
    height: '100%',
  },
  advcoltext: {
    width: ['100%', '100%', '100%', '50%', '50%'],
    p: '10px',

    flexBox: true,
    alignItems: 'center',
  },
  adtitle: {
    fontSize: ['12px', '12px', '12px', '12px', '12px'],
    fontWeight: 'bold',
    // color: '#0f2137',
    color: '#000000',
    // letterSpacing: "-0.025em",
    lineHeight: '1.31',
    textAlign: ['center', 'center', 'left', 'left', 'left'],
    m: '0px !important',
  },
  adtext: {
    fontSize: ['16px', '16px', '16px', '16px', '16px'],
    fontWeight: 'normal',
    // color: '#0f2137',
    color: '#393E99',
    letterSpacing: '-0.025em',
    lineHeight: '1.31',
    textAlign: ['center', 'center', 'left', 'left', 'left'],
  },
  imagecont: {
    // position: "absolute",
    // ml: "-150px",
  },
  pipe: {
    borderLeft: ['none', 'none', 'none', '1px solid grey', '1px solid grey'],
  },
};

export default BooknowBanner;
